<template>
  <div class="trans-overview">
    <h1>Transaction Overview</h1>
    <hr />
    <div class="tables-container">
      <simple-table :data="todayData" title="Today"></simple-table>
      <simple-table :data="last30DaysData" title="Last 30 days"></simple-table>
    </div>
  </div>
</template>

<script>
import SimpleTable from "@/components/UI/SimpleTable/SimpleTable.vue";
import emitter from "@/services/eventBus";
import dashboardService from "@/services/dashboardService";

export default {
  mounted() {
    this.getTodayData();
    this.getLastMonthData();
    // This is to refresh data when sync button is clicked
    emitter.on("refresh-data", () => {
      this.getTodayData();
      this.getLastMonthData();
    });
  },
  components: { SimpleTable },
  data() {
    return {
      todayData: [
        { type: "APPROVE", amount: 0, orders: 0 },
        { type: "REJECT", amount: 0, orders: 0 },
        { type: "REVIEW", amount: 0, orders: 0 },
      ],
      last30DaysData: [
        { type: "APPROVE", amount: 0, orders: 0 },
        { type: "REJECT", amount: 0, orders: 0 },
        { type: "REVIEW", amount: 0, orders: 0 },
      ],
    };
  },
  methods: {
    getTodayData() {
      dashboardService.getDailyData().then((result) => {
        if (
          result.original.error?.message === "there are no transactions today"
        ) {
          return;
        }

        let { ApproveAmount, ApproveOrdersCount, ApproveStatus } =
          result.original.response.data;
        let { RefundedAmount, RefundedOrdersCount, RefundedStatus } =
          result.original.response.data;
        let { ReviewAmount, ReviewOrdersCount, ReviewStatus } =
          result.original.response.data;
        this.todayData = [
          {
            type: ApproveStatus,
            amount: ApproveAmount,
            orders: ApproveOrdersCount,
          },
          {
            type: RefundedStatus,
            amount: RefundedAmount,
            orders: RefundedOrdersCount,
          },
          {
            type: ReviewStatus,
            amount: ReviewAmount,
            orders: ReviewOrdersCount,
          },
        ];
      });
    },
    getLastMonthData() {
      dashboardService.getMonthlyData().then((result) => {
        if (
          result.original.response?.message ===
          "there are no transactions in this month"
        ) {
          return;
        }

        let { ApproveAmount, ApproveOrdersCount, ApproveStatus } =
          result.original.response.data;
        let { RefundedAmount, RefundedOrdersCount, RefundedStatus } =
          result.original.response.data;
        let { ReviewAmount, ReviewOrdersCount, ReviewStatus } =
          result.original.response.data;

        this.last30DaysData = [
          {
            type: ApproveStatus,
            amount: ApproveAmount,
            orders: ApproveOrdersCount,
          },
          {
            type: RefundedStatus,
            amount: RefundedAmount,
            orders: RefundedOrdersCount,
          },
          {
            type: ReviewStatus,
            amount: ReviewAmount,
            orders: ReviewOrdersCount,
          },
        ];
      });
    },
  },
};
</script>

<style scoped>
.trans-overview {
  padding-top: 45px;
}
h1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 17px;
}

hr {
  border: 1px solid #e7e7e7;
  margin-bottom: 6px;
}

.tables-container {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  column-gap: 32px;
}

@media only screen and (max-width: 950px) {
  .tables-container {
    grid-template-columns: 1fr;
  }
}
</style>
