<template>
  <div
    :class="{
      'title-bar': title !== 'Transaction Listing',
      'title-bar-custom': title == 'Transaction Listing',
    }"
  >
    <p class="title-bar-left">{{ title }}</p>
    <!-- Conditional rendring for title bar contents -->
    <!-- 1-Dashboard -->
    <div class="title-bar-right" v-if="title === 'FraudLabs Pro'">
      <base-button @click="syncOrders"> Sync from Fraudlabs </base-button>
    </div>
    <!-- 2-Listings -->
    <div
      :class="{
        'title-bar-right': title !== 'Transaction Listing',
        'title-bar--right-custom': title == 'Transaction Listing',
      }"
      v-else-if="title === 'Transaction Listing'"
    >
      <!-- filter by date -->
      <div class="card flex justify-center align-center">
        <!-- <Dropdown
          :options="dateOptions"
          v-model="store.rangeDateOption"
          optionLabel="name"
          optionValue="value"
          style="
            width: 160px;
            border: 1px solid #d8d8d8;
            border-radius: 0 !important;
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
            border-right: 0 !important;
          "
        ></Dropdown> -->

        <p class="filter-date-text">Filter by date :</p>
        <!-- <Calendar v-model="store.filterByDate" dateFormat="dd/mm/yy" /> -->
        <Calendar
          class="inputtext"
          v-model="date"
          dateFormat="dd/mm/yy"
          selectionMode="range"
          :manualInput="false"
        />
        <button class="calendar-btn">
          <i class="pi pi-calendar" style="font-size: 1rem"></i>
        </button>
      </div>

      <!-- Filter Component -->
      <Dropdown
        :options="filterOptions"
        v-model="store.filterOption"
        optionLabel="name"
        optionValue="value"
        style="width: 200px; border: 1px solid #d8d8d8; border-radius: 8px"
      ></Dropdown>
      <!-- Search Component -->
      <SearchInputButton />
      <base-button @click="clearFilters" v-tooltip.left="'Reset Filters'">
        <i class="pi pi-filter-slash" style="font-size: 1rem"></i>
      </base-button>
      <div
        style="padding-left: 20px; border-left: 1px solid rgb(229, 231, 235)"
      >
        <base-button
          class="flat"
          @click="refreshTable"
          v-tooltip.left="'Refresh Table'"
        >
          <i class="pi pi-refresh" style="font-size: 14px"></i>
        </base-button>
      </div>
    </div>
  </div>
  <notification-message
    v-if="visibleNotification"
    :class="message !== 'Successfully Synced' && 'invalid-alert'"
  >
    {{ message }}
  </notification-message>
</template>

<script>
// import DatePicker from "litepie-datepicker";

import Calendar from "primevue/calendar";

// import { FilterMatchMode, FilterOperator } from "primevue/api";

import dashboardService from "@/services/dashboardService";
import { searchAndFilter } from "@/stores/searchAndFilterListing";
import emitter from "@/services/eventBus";
import SearchInputButton from "../SearchInputButton/SearchInputButton.vue";

export default {
  components: { SearchInputButton, Calendar },
  props: ["title"],
  methods: {
    showNotification(message) {
      //show notification and hide it after 0.5 second
      this.message = message;
      this.visibleNotification = true;
      setTimeout(() => {
        this.visibleNotification = false;
      }, 2000);
    },
    syncOrders() {
      if (this.visibleNotification) return;
      dashboardService.syncOrders().then((data) => {
        if (data[0].status === "success") {
          this.showNotification("Successfully Synced");
          emitter.emit("refresh-data");
        }
        if (data[0].message === "FraudLabs key is empty") {
          this.showNotification("Failed to Sync, API key is missing.");
        }
      });
    },
    clearFilters() {
      // Reset filterOption and filterByDate to default values
      this.store.filterOption = "none";
      this.store.startDate = "";
      this.store.endDate = "";
      this.store.filterByDate = ""; // or '' if you're binding to an input field that expects a string
      this.date = "";

      // Clear other filters if necessary
      this.store.searchTerm = "";
      this.store.searchBy = "fraud_labs_id";
      this.store.rangeDateOption = "dateIs";

      // Emit events to clear input and refresh data table
      emitter.emit("clear-input");
      emitter.emit("clear-filters");
      // // if there is no filters return
      // if (
      //   this.store.filterLazyParam.fraud_lab_order_status.value === "" &&
      //   this.store.filterLazyParam.fraud_labs_id === undefined &&
      //   this.store.filterLazyParam.order_id_hash === undefined
      // ) {
      //   // this emitted event will clear the search input
      //   emitter.emit("clear-input");
      //   return;
      // }
      // // this emitted event will clear any added filters through lazyParams
      // emitter.emit("clear-filters");
    },
    refreshTable() {
      // clear filters first
      this.clearFilters();
      // this emitted event will refresh datatable
      emitter.emit("refresh-datatable");
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
  },
  data() {
    return {
      message: "",
      store: searchAndFilter(),
      visibleNotification: false,
      date: "",
      filterOptions: [
        { name: "Filter by: None", value: "none" },
        { name: "Filter by: Approved", value: "approved" },
        { name: "Filter by: Pending", value: "pending" },
        { name: "Filter by: Rejected", value: "rejected" },
      ],
      dateOptions: [
        { name: "Date on", value: "dateIs" },
        { name: "Date not on", value: "dateIsNot" },
        { name: "Date before", value: "dateBefore" },
        { name: "Date after", value: "dateAfter" },
        { name: "Date between", value: "datebetween" },
      ],
    };
  },
  watch: {
    date: {
      handler(newValue) {
        console.log(this.date);
        if (newValue && newValue[1] != null) {
          const [startDate, endDate] = newValue;
          console.log(startDate, endDate);

          const formattedStartDate = this.formatDate(startDate);
          const formattedEndDate = this.formatDate(endDate);
          let datess = this.formatDate(null);
          //      startDate:'',
          // endDate:'',
          this.store.startDate = formattedStartDate;
          this.store.endDate = formattedEndDate;
          console.log(datess);
          // console.log(
          //   "Date range selected:",
          //   this.store.startDate,
          //   "-",
          //   this.store.endDate
          // );
          // You can use these dates for filtering or other logic
        } else {
          this.store.startDate = "";
          this.store.endDate = "";
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.inputtext {
  border-radius: 8px 0 0 8px !important;
}
.filter-date-text {
  font-size: 16px;
  line-height: 24px;
  padding-right: 10px;
}
.align-center {
  align-items: center;
}
.title-bar {
  display: flex;
  justify-content: space-between;
  padding: 17px 0 14px 0;
}

.title-bar-custom {
  display: flex;
  justify-content: space-between;
  padding: 17px 0 14px 0;
  flex-direction: column;
}
.title-bar-left {
  display: flex;
  height: 45px;
  align-items: center;
  color: #202223;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

.title-bar-right {
  display: flex;
  align-items: center;
  gap: 12px;
}
.title-bar--right-custom {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
}
.edit-btns {
  display: flex;
  gap: 12px;
}

.title-bar-right-email-template {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media only screen and (max-width: 1100px) {
  /* .title-bar {
    align-items: start;
  }
  .title-bar-right {
    flex-direction: column;
    align-items: end;
  } */
}
.calendar-btn {
  border-radius: 0 8px 8px 0 !important;
  color: #fff;
  cursor: inherit;
  padding: 0 15px;
  height: 42px;
  font-size: 14px;
  background-color: #a81d1b !important;
}
</style>
