<template>
  <section v-if="statusApp">
    <!-- Upper bar -->
    <UpperBar title="FraudLabs Pro">
      <PluginLogoSvg></PluginLogoSvg>
    </UpperBar>
    <!-- Titile bar -->
    <title-bar :title="currentTitle"></title-bar>
    <!-- Nav bar -->
    <nav-bar></nav-bar>
    <!-- View router -->
    <div class="menu-tab">
      <router-view @get-title="handleTabTitle"></router-view>
    </div>
  </section>
  <section class="loading" v-else>
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </section>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { authLogin } from "./services/authLogin";

import UpperBar from "./components/UI/UpperBar/UpperBar.vue";
import PluginLogoSvg from "./components/UI/UpperBar/PluginLogoSvg.vue";
import TitleBar from "./components/UI/TitleBar/TitleBar.vue";
import NavBar from "./components/UI/NavBar/NavBar.vue";

export default {
  name: "App",
  components: { UpperBar, PluginLogoSvg, TitleBar, NavBar },

  setup() {
    const currentTitle = ref("");

    const statusApp = ref(false);
    const auth = new authLogin();
    const router = useRouter();

    auth.startApp()?.then((data) => {
      if (!data.meta.redirect) {
        statusApp.value = true;
        router.push("/flabspro");
      }
    });

    const handleTabTitle = (tabTitle) => {
      currentTitle.value = tabTitle;
    };

    return {
      currentTitle,
      handleTabTitle,
      statusApp,
    };
  },
};
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  padding: 44px;
  min-height: 100vh;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  background-color: #f2f5fa;
  scrollbar-gutter: "stable";
}

.menu-tab {
  background: #ffffff;
  border: 1px solid #edeeef;
  border-top-width: 0;
  border-radius: 0 0 12px 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;
  position: relative;
}

/* Hide number arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

@media only screen and (max-width: 1200px) {
  #app {
    padding: 40px;
  }
}
@media only screen and (max-width: 1000px) {
  #app {
    padding: 35px;
  }
}
@media only screen and (max-width: 800px) {
  #app {
    padding: 30px;
  }
}
@media only screen and (max-width: 600px) {
  #app {
    padding: 25px;
  }
}
@media only screen and (max-width: 400px) {
  #app {
    padding: 20px;
  }
}
@media only screen and (max-width: 350px) {
  #app {
    padding: 15px;
  }
}
</style>
