<template>
  <div class="license-overview">
    <h6>License Overview</h6>
    <hr />
    <div class="license-overview-body">
      <div class="column">
        <div>
          <p>{{ availableCredits }}</p>
          <p class="attribute">Available credits</p>
        </div>
        <div>
          <p>{{ nextRenewalDate }}</p>
          <p class="attribute">Next renuewal date</p>
        </div>
        <div>
          <p>
            {{
              apiKey !== "NA"
                ? "*".repeat(8) + apiKey.substring(8, 17) + "*".repeat(10)
                : "NA"
            }}
          </p>
          <p class="attribute">API Key</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import dashboardService from "@/services/dashboardService";
import emitter from "@/services/eventBus";

import { onMounted, ref } from "vue";
const availableCredits = ref("");
const nextRenewalDate = ref("");
const apiKey = ref("");

function unixTimestampToDateString(timestamp) {
  if (timestamp === undefined) {
    return "NA";
  }
  const date = new Date(timestamp * 1000);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
}

function getLicenseData() {
  dashboardService
    .getLicenseData()
    .then((result) => {
      availableCredits.value = result["Available Credits"] || "NA";
      apiKey.value = result["API Key"] || "NA";
      nextRenewalDate.value = unixTimestampToDateString(
        result["Next Renewal Date"]
      );
    })
    .catch((err) => console.log(err));
}

onMounted(() => {
  getLicenseData();
  emitter.on("refresh-data", () => {
    getLicenseData();
  });
});
</script>

<style scoped lang="scss">
.license-overview {
  width: 100%;

  h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  hr {
    margin: 17px 0;
  }

  .license-overview-body {
    .column {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .attribute {
      font-size: 12px;
      line-height: 16px;
      color: #6d6d6d;
    }
  }
}

@media only screen and (max-width: 740px) {
  .license-overview-body {
    flex-direction: column;
    gap: 12px;
  }
}
</style>
