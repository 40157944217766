<template>
  <div class="dashboard-table-container">
    <p class="dashboard-title">{{ title }}</p>
    <DataTable
      class="dashboard-table"
      :value="data"
      :rows="3"
      responsiveLayout="scroll"
    >
      <Column field="type" header="">
        <template #body="{ data }">
          <div class="type">
            <ApprovedSvg v-if="data.type === 'APPROVE'" />
            <RejectedSvg v-if="data.type === 'REJECT'" />
            <PendingSvg v-if="data.type === 'REVIEW'" />
            <span v-if="data.type === 'APPROVE'">Approved</span>
            <span v-if="data.type === 'REJECT'">Rejected</span>
            <span v-if="data.type === 'REVIEW'">Pending</span>
          </div>
        </template>
      </Column>
      <Column field="amount" header="Amount">
        <template #body="{ data }">
          <span>${{ data.amount.toFixed(2) }}</span>
        </template>
      </Column>
      <Column field="orders" header="Orders"></Column>
      <Column field="link" header="">
        <template #body="{ data }">
          <router-link
            to="/transaction-listings"
            @click="filterData(data.type)"
            class="link"
          >
            <span>View</span>
            <span>
              <RightArrowSvg />
            </span>
          </router-link>
        </template>
      </Column>
      <template #empty> No records found. </template>
    </DataTable>
  </div>
</template>

<script>
import RightArrowSvg from "./icons/RightArrowSvg.vue";
import ApprovedSvg from "./icons/ApprovedSvg.vue";
import RejectedSvg from "./icons/RejectedSvg.vue";
import PendingSvg from "./icons/PendingSvg.vue";
import { searchAndFilter } from "@/stores/searchAndFilterListing";

export default {
  props: ["title", "data"],
  data() {
    return {
      store: searchAndFilter(),
    };
  },
  components: { RightArrowSvg, ApprovedSvg, RejectedSvg, PendingSvg },
  methods: {
    filterData(status) {
      switch (status) {
        case "APPROVE":
          this.store.filterOption = "approved";
          break;
        case "REJECT":
          this.store.filterOption = "rejected";
          break;
        case "REVIEW":
          this.store.filterOption = "pending";
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-table-container {
  display: flex;
  flex-direction: column;

  .dashboard-title {
    font-weight: 600;
    margin-bottom: 12px;
  }
  .type {
    display: flex;
    align-items: center;
    gap: 8px;
    text-transform: capitalize;
  }
  .link {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    span {
      color: #a81d1b;
    }
    svg {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  :deep(.p-column-title),
  :deep(.p-datatable-tbody td) {
    font-family: "Plus Jakarta Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #202223;
  }

  :deep(.p-column-title) {
    font-weight: 600;
  }
}

.dashboard-table {
  border: 1px solid #edeeef;
  border-radius: 8px;
  overflow: hidden;
}
</style>
