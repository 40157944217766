<template>
  <nav>
    <ul>
      <li v-for="item in navData" :key="item.tabRoute">
        <router-link :to="item.tabRoute">
          <span>{{ item.tabLabel }}</span>
          <warning-svg
            v-if="item.tabLabel === 'Settings' && visibleWarning"
          ></warning-svg>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import settingsService from "@/services/settingsService";
import WarningSvg from "./Icons/WarningSvg.vue";
import emitter from "@/services/eventBus";

export default {
  components: { WarningSvg },
  mounted() {
    // listen whenever user turned on auto refund settings
    emitter.on("hide-refund-warning", () => {
      this.visibleWarning = false;
    });
    // listen whenever user turned off auto refund settings
    emitter.on("show-refund-warning", () => {
      this.visibleWarning = true;
    });

    settingsService
      .getPluginSettings()
      .then((result) => {
        let autoRefundPending;
        let autoRefundRejected;
        if (result.original.status === "success") {
          if (Object.keys(result.original.details).length === 0) {
            return;
          }
          autoRefundPending = Boolean(
            result.original.details.pending_auto_refund
          );
          autoRefundRejected = Boolean(
            result.original.details.rejected_auto_refund
          );

          if (!autoRefundPending || !autoRefundRejected) {
            this.visibleWarning = true;
          } else {
            this.visibleWarning = false;
          }
        }
      })
      .catch((err) => console.log(err));
  },

  data() {
    return {
      visibleWarning: true,
      navData: [
        {
          tabLabel: "Dashboard",
          tabRoute: "/flabspro",
        },
        {
          tabLabel: "Transaction Listing",
          tabRoute: "/transaction-listings",
        },
        {
          tabLabel: "Settings",
          tabRoute: "/settings",
        },
        {
          tabLabel: "API Key Settings",
          tabRoute: "/api-key-settings",
        },
      ],
    };
  },
};
</script>

<style scoped>
nav {
  padding: 0 28px;
  width: 100%;
  height: 65px;
  background-color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #6d6d6d;
}

ul {
  height: 100%;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 53px;
  border-bottom: 1px solid #cecece;
}

ul li {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

ul li:last-of-type {
  margin-left: auto;
}

ul li:hover {
  box-shadow: 0 4px 0 #cecece;
}

.active-tab {
  box-shadow: 0 4px 0 #a81d1b;
  color: #a81d1b;
}

ul li span {
  margin-right: 10px;
}

li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #6d6d6d;
  height: 100%;
}

@media only screen and (max-width: 530px) {
  nav {
    height: auto;
    padding: 20px;
  }
  ul {
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    gap: 20px;
    border-bottom: none;
    flex-direction: column;
  }
  ul li:last-of-type {
    margin-left: 0;
  }
}
</style>
