<!-- 
  This Statistics Card should be reusable for all plugins:
  1- props needed from parent: title and count to be shown.
  2- classes and colors are adjusted here.
 -->
<template>
  <div class="stats-card">
    <div class="stats-card-text">
      <p class="stats-card-info">{{ cardCount }}</p>
      <p>{{ cardTitle }}</p>
    </div>
    <div>
      <slot>
        <p>Insert Image or Svg Here</p>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  props: ["cardTitle", "cardCount"],
};
</script>
<style scoped lang="scss">
.stats-card {
  padding-left: 21px;
  padding-right: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 321px;
  min-width: 300px;
  height: 144px;
  border-radius: 8px;

  .stats-card-text {
    display: flex;
    flex-direction: column;
    .stats-card-info {
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
    }
  }
}

.approved-orders {
  background: #e7fff9;
  border-bottom: 3px solid #07d09e;
  box-shadow: 0px 5px 19px #e7fff9;
}
.denied-orders {
  background: #ffd2ca;
  border-bottom: 3px solid #ff684d;
  box-shadow: 0px 5px 19px #ffe4df;
}
.pending-orders {
  background: #ffeecd;
  border-bottom: 3px solid #ffc453;
  box-shadow: 0px 5px 19px #ffeecd;
}

.approved-orders:hover {
  background: #cefff2;
  position: relative;
  top: 5px;
}
.denied-orders:hover {
  background: #ffc6bc;
  position: relative;
  top: 5px;
}
.pending-orders:hover {
  background: #ffe9be;
  position: relative;
  top: 5px;
}

@media only screen and (max-width: 1500px) {
  .stats-card {
    min-width: 250px;
  }
}
@media only screen and (max-width: 1350px) {
  .stats-card {
    min-width: 225px;
  }
}
@media only screen and (max-width: 1300px) {
  .stats-card {
    min-width: 200px;
  }
}
@media only screen and (max-width: 1200px) {
  .stats-card {
    min-width: 250px;
  }
}
@media only screen and (max-width: 1100px) {
  .stats-card {
    min-width: 225px;
  }
}
@media only screen and (max-width: 1050px) {
  .stats-card {
    min-width: 200px;
  }
}
@media only screen and (max-width: 980px) {
  .stats-card {
    min-width: 300px;
  }
}
@media only screen and (max-width: 880px) {
  .stats-card {
    min-width: 275px;
  }
}
@media only screen and (max-width: 840px) {
  .stats-card {
    min-width: 250px;
  }
}
@media only screen and (max-width: 800px) {
  .stats-card {
    min-width: 225px;
  }
}
@media only screen and (max-width: 780px) {
  .stats-card {
    min-width: 200px;
  }
}
@media only screen and (max-width: 740px) {
  .stats-card {
    min-width: 300px;
  }
}
</style>
