<template>
  <div class="api-settings">
    <p>Enter Your Api Token</p>
    <div class="body">
      <!-- <input type="text" placeholder="API Key" v-model="apiKey" /> -->
      <div>
        <PasswordInput
          :class="!validKey && 'p-invalid'"
          v-model="apiKey"
          placeholder="API Key"
          :toggle-mask="true"
          :feedback="false"
          :input-style="{ width: '350px', fontSize: '16px' }"
        />
        <p class="invalid" v-if="!validKey">
          API key must be 32 capital characters
        </p>
      </div>
      <base-button @click="saveApiKey" :disabled="!validKey"
        >Submit</base-button
      >
    </div>
  </div>
  <notification-message
    :class="fraudInvalid && 'invalid-alert'"
    v-if="visibleNotification"
  >
    {{ message }}
  </notification-message>
</template>

<script>
import emitter from "@/services/eventBus";
import settingsService from "@/services/settingsService";
import PasswordInput from "primevue/password";

export default {
  emits: ["getTitle"],
  components: { PasswordInput },
  watch: {
    apiKey(value) {
      if (/^[A-Z0-9]{32}$/.test(value.trim())) {
        this.validKey = true;
      } else {
        this.validKey = false;
      }
    },
  },
  data() {
    return {
      apiKey: "",
      validKey: true,
      message: "",
      visibleNotification: false,
      fraudInvalid: false,
    };
  },
  mounted() {
    this.$emit("getTitle", "Api Key Settings");
    settingsService.getPluginApiKey().then((result) => {
      if (result.original.status === "success") {
        this.apiKey = result.original.details.fraud_lab_key;
      }
    });
  },
  methods: {
    showNotification(message) {
      this.message = message;
      //show notification and hide it after 0.5 second
      this.visibleNotification = true;
      setTimeout(() => {
        this.visibleNotification = false;
      }, 800);
    },
    saveApiKey() {
      this.fraudInvalid = false;
      settingsService.savePluginApiKey(this.apiKey).then((result) => {
        if (result.original.success?.status === "success") {
          emitter.emit("hide-missing-key-warning");
          this.showNotification("Key is correct and added successfully!");
        }
        if (result.original.status === "failed") {
          this.fraudInvalid = true;
          this.showNotification("Key is INVALID, Please enter a valid key!");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.api-settings {
  min-height: 200px;
  padding: 22px 27px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
  }
  div.body {
    display: flex;
    gap: 13px;
    input {
      padding: 8px 20px;
      width: 480px;
      height: 40px;
      background: #fbfbfb;
      border: 1px solid #d8d8d8;
      border-radius: 8px;
      outline: none;
    }
    input:focus {
      box-shadow: 0 0 5px rgba(81, 203, 238, 1);
      border: 1px solid rgba(81, 203, 238, 1);
    }
    p.invalid {
      font-size: 16px;
      color: crimson;
    }
  }
}
</style>
